/* loading */
#overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1); /* Black background with opacity */
    opacity: 0.8;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: progress; /* Add a pointer on hover */
}

/* HTML: <div class="loader"></div> */
.leaving {
    position: absolute;
    z-index: 99999;
    left: 50%;
    top: 50%;
    width: 35px;
    aspect-ratio: 1;
    /* color:  rgb(45,120,219); */
    /* font-weight: bold; */
    /* font-family: sans-serif; */
    font-size: 0.9rem;
    padding-bottom: 8px;
    background: linear-gradient(currentColor 0 0) 0 100%/0% 3px no-repeat;
    animation: l2 2s linear infinite;
  }
  .leaving:before {
    content:"Redirecting..."
  }
  @keyframes l2 {to{background-size: 100% 3px}}


/* new spinner */
/* .leaving {
    position: absolute;
    z-index: 99999;
    left: 50%;
    top: 50%;
    width: 35px;
    aspect-ratio: 1;
    border: 7px solid #ddd;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
  }
  .leaving::before {
    content: "";
    position: absolute;
    inset: -7px;
    border-radius: 50%;
    border: 7px solid rgb(45,120,219);
    animation: l18 2s infinite linear;
  }
  @keyframes l18 {
      0%   {clip-path:polygon(50% 50%,0 0,0    0,0    0   ,0    0   ,0    0   )}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0   ,100% 0   ,100% 0   )}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    0   )}
  } */