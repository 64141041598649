/* loading */
#overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1); /* Black background with opacity */
    opacity: 0.5;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: progress; /* Add a pointer on hover */
}

/* old spinner */
/* .spinner {
    position: absolute;
    z-index: 99999;
    left: 50%;
    top: 50%;
    height:45px;
    width:45px;
    margin:0px auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(45,120,219,.15);
    border-right:6px solid rgba(45,120,219,.15);
    border-bottom:6px solid rgba(45,120,219,.15);
    border-top:6px solid rgba(45,120,219,.8);
    border-radius:100%;
 }
 
 @-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
 }
 @-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
 }
 @-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
 }
 @keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
 }  */

/* new spinner */
.spinner {
    position: absolute;
    z-index: 99999;
    left: 50%;
    top: 50%;
    width: 35px;
    aspect-ratio: 1;
    border: 7px solid #ddd;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
  }
  .spinner::before {
    content: "";
    position: absolute;
    inset: -7px;
    border-radius: 50%;
    border: 7px solid rgb(45,120,219);
    animation: l18 2s infinite linear;
  }
  @keyframes l18 {
      0%   {clip-path:polygon(50% 50%,0 0,0    0,0    0   ,0    0   ,0    0   )}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0   ,100% 0   ,100% 0   )}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    0   )}
  }