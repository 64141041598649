@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
  height: 8px; /* Height of the entire scrollbar (for horizontal scrollbar) */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.rdt_TableCol {
  color: rgb(128, 128, 128);
}

.rdt_resetPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.another-class:last-child {
  display: none;
  width: 1px !important;
  height: 1px !important;
  cursor: not-allowed !important;
}

/* .rdt_TableRow button {
    background-color: inherit;
}

.rdt_TableRow button:hover, .rdt_TableRow button:active, .rdt_TableRow:focus {
    background-color: red;
} */

.rdt_TableCol div {
  white-space: normal !important;
  overflow: visible !important;
  width: fit-content;
  /* text-align: center; */
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

input:disabled,
select:disabled {
  background: #ebebeb;
  color: rgb(15, 44, 118);
}

/* Custom classes for striped table */
.custom-table tbody tr {
  @apply bg-white;
}
.custom-table tbody tr td {
  @apply my-2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  * {
    @apply text-[0.90rem] 2xl:text-[0.92rem] tracking-wide font-poppins;
  }
}
